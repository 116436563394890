export const toISODateFromMDY = (birthDate: string): string => {
  const [month, day, year] = birthDate.split('/');
  return [year, month, day].join('-');
};

export const toISODateFromDMY = (birthDate: string): string => {
  const [day, month, year] = birthDate.split('/');
  return [year, month, day].join('-');
};

export const dateTransforms = { toISODateFromDMY, toISODateFromMDY };

export const toMDYFromDMY = (birthDate: string): string => {
  const [day, month, year] = birthDate.split('/');
  return [month, day, year].join('/');
};

/**
 * Returns a human-readable duration until the provided ISO timestamp
 * e.g. "1 hour" or "30 minutes" or "1 hour and 30 minutes"
 */
export const timeUntil = (timestamp: string | undefined): string => {
  if (timestamp) {
    const lockedUntilDate = new Date(timestamp);
    const now = new Date();
    const timeDifference = lockedUntilDate.getTime() - now.getTime();
    if (timeDifference < 0) {
      return '';
    }

    const lockoutDurationMinutes = Math.ceil(timeDifference / (1000 * 60));
    const lockoutDurationHours = Math.floor(lockoutDurationMinutes / 60);

    let minuteS = '';
    if (lockoutDurationMinutes > 1) {
      minuteS = 's';
    }
    let hourS = '';
    if (lockoutDurationHours > 1) {
      hourS = 's';
    }

    if (lockoutDurationMinutes < 60) {
      return lockoutDurationMinutes + ' minute' + minuteS;
    } else if (lockoutDurationMinutes % 60 === 0) {
      return lockoutDurationHours + ' hour' + hourS;
    } else {
      return (
        lockoutDurationHours +
        ' hour' +
        hourS +
        ' and ' +
        (lockoutDurationMinutes % 60) +
        ' minute' +
        minuteS
      );
    }
  }

  return '';
};
