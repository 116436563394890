import { useField } from 'formik';
import React, { useState } from 'react';
import { classNames } from '../../lib/classNames';
import { usePrefilledField } from '../../lib/hooks/usePrefilledField';
import { useYupValidationSchema } from '../../components/YupValidationSchemaContext';
import { FCWithChildren } from '../../lib/types';
import { InlineError } from './InlineError';

interface TextFieldProps {
  label: string;
  name: string;
  autofillName?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  excludePII?: boolean;
  required?: boolean;
  labelTooltip?: JSX.Element;
}

export const TextField: FCWithChildren<TextFieldProps> = ({
  label,
  className = '',
  placeholder,
  disabled,
  autoFocus,
  children,
  autofillName,
  excludePII = false,
  required,
  labelTooltip,
  ...props
}) => {
  const { isRequiredField } = useYupValidationSchema();
  const [field, meta, helpers] = useField(props);
  const { name, ...fieldRest } = field;
  const { prefilled, setPrefilled } = usePrefilledField(field);
  const isError = (meta.touched || prefilled) && meta.error;
  const [didFocus, setDidFocus] = useState(false);
  const inputId = name.replace('.', '-');
  return (
    <div className={`mb-3 w-full ${className}`}>
      <div className="flex justify-start items-center">
        <label
          className={`relative text-sm text-dark-blue-grey `}
          htmlFor={inputId}
        >
          <span className="flex mb-1 leading-loose whitespace-nowrap">
            {label}
          </span>
        </label>
        <div>{labelTooltip}</div>
      </div>
      <div className="items-center justify-end relative">
        <input
          id={inputId}
          name={name}
          className={classNames(
            `border outline-none rounded-sm px-2 py-1 mb-1 w-full h-10 text-blackberry text-base bg-white`,
            isError
              ? 'border-theme-danger focus:ring-darker-blue-grey focus:ring-2 focus:ring-offset-2 focus:outline-none'
              : 'border-dark-blue-grey focus:ring-darker-blue-grey focus:ring-2 focus:ring-offset-2 focus:outline-none',
            disabled && 'bg-white-grey opacity-70',
            excludePII && 'fs-exclude'
          )}
          aria-invalid={isError ? 'true' : 'false'}
          aria-describedby={isError ? `${name}-error` : undefined}
          aria-required={required || isRequiredField(name)}
          required={required || isRequiredField(name)}
          type="text"
          autoComplete={autofillName || 'off'}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          data-testid={`input-${name}`}
          {...fieldRest}
          onFocus={() => {
            setDidFocus(true);
          }}
          onBlur={(e) => {
            field.onBlur(e);
          }}
          onChange={(e) => {
            // If the field has not yet been focussed the onChange event is
            // likely coming from browser autofill or an extension. In that
            // case errors should be visible immediately instead of upon blur.
            if (!didFocus) {
              helpers.setTouched(true, false);
            }
            setPrefilled(false);
            field.onChange(e);
          }}
        />
        {children && (
          <div className="inline-block absolute right-2.5 leading-loose cursor-pointer">
            {children}
          </div>
        )}
      </div>
      <InlineError
        name={name}
        error={meta.error}
        label={label}
        touched={meta.touched || prefilled}
      />
    </div>
  );
};
