import React, { ReactNode } from 'react';
import { Spinner } from './svgs/Spinner';

interface LoadingSpinnerProps {
  isLoading: boolean;
  children: ReactNode;
  className?: string;
}

const LoadingSpinner = ({
  isLoading,
  children,
  className = 'flex justify-center mb-6',
}: LoadingSpinnerProps): JSX.Element => {
  if (isLoading) {
    return (
      <div className={className} data-testid="loading-spinner">
        <Spinner aria-hidden="true" className="w-12 h-12 text-theme-primary" />
      </div>
    );
  }

  return <>{children}</>;
};

export default LoadingSpinner;
