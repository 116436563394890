import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useSDKOptions } from '../../XPropsContext';
import { useToast } from '../../Toast';
import { APIForm } from '../../APIForm';
import { SubmitError } from '../../forms/SubmitError';
import { Button } from '../../Button';
import { BackLinkProps } from '../../BackLink';
import LoadingSpinner from '../../LoadingSpinner';
import { FCWithChildren } from '../../../lib/types';
import { useBuyerMachine } from '../../BuyerMachineContext';
import { RouteMachineServiceScreenProps } from '../../RouteMachineService';

type Props = {
  header: ReactNode;
  BackLink: FunctionComponent<BackLinkProps>;
  subHeader?: ReactNode;
  subtitle?: ReactNode;
  disclosure?: ReactNode;
} & RouteMachineServiceScreenProps;

export const emailValidationSchema: yup.ObjectSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .matches(/^.*\..{2,64}$/)
    .max(320)
    .required(),
});

export const Email: FCWithChildren<Props> = ({
  children,
  forward,
  back,
  disclosure,
  header,
  BackLink,
  subHeader,
  subtitle,
}) => {
  const { email: contextEmail, assignBuyerEmail } = useBuyerMachine();
  const { isPreview } = useSDKOptions();

  const { closeNotification } = useToast();

  const initialEmailFormValues = {
    email: contextEmail || '',
  };

  type EmailFormValues = typeof initialEmailFormValues;

  const handleSubmit: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>
  ) => void | Promise<void> = (values) => {
    closeNotification();
    assignBuyerEmail(values.email);
    forward();
  };

  return (
    <>
      {header}
      {subHeader}
      {subtitle}
      <APIForm<EmailFormValues>
        initialValues={initialEmailFormValues}
        onSubmit={handleSubmit}
        validationSchema={emailValidationSchema}
      >
        {({ isFormDisabled, isSubmitting, error }) => (
          <>
            {children}
            {error && <SubmitError error={error} />}
            {disclosure}
            <LoadingSpinner
              isLoading={isSubmitting}
              className="mt-10 flex justify-center mb-6"
            >
              <Button
                type="submit"
                disabled={isFormDisabled || isPreview}
                className="block mt-10"
              >
                <FormattedMessage
                  defaultMessage="Continue"
                  description="Email screen button text"
                />
              </Button>
            </LoadingSpinner>
          </>
        )}
      </APIForm>
      <div className="text-center">
        <BackLink onClick={() => back()} />
      </div>
    </>
  );
};

export const EmailBaseSubtitle: FunctionComponent<{
  tooltip?: FunctionComponent;
}> = ({ tooltip: Tooltip }) => {
  return (
    <h2 className="mb-6 font-bold">
      <FormattedMessage
        defaultMessage="Enter your email to log back in or get <NoWrapTooltip>started: </NoWrapTooltip>"
        description="Email verification screen sub-heading"
        values={{
          NoWrapTooltip: (chunks: ReactNode) => (
            <span className="whitespace-nowrap">
              {chunks}
              {Tooltip && <Tooltip />}
            </span>
          ),
        }}
      />
    </h2>
  );
};
