import { FieldInputProps } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Detects if a field will be prefilled on load and sets prefilled to true.
 * @param field - The field to detect if it will be prefilled
 * @returns an object that contains prefilled boolean and setPrefilled function to update it.
 */
export function usePrefilledField(field: FieldInputProps<any>): {
  prefilled: boolean;
  setPrefilled: Dispatch<SetStateAction<boolean>>;
} {
  const [prefilled, setPrefilled] = useState(false);

  useEffect(() => {
    if (field.value?.length > 0) {
      setPrefilled(true);
    }
  }, []);

  return { prefilled, setPrefilled };
}
